<template>
	<div class="form-group" v-if="loaded">
		<div class="data-group">
			<div class="title">{{ $t('salary.peoples.title') }}</div>
		</div>
		<div class="form">
			<div class="input-group w-100">
				<div class="label-header">
					<label class="label">{{ $t('salary.peoples.choose_people') }}*</label>
					<div v-if="validator.selectedUser.$error" class="error-msg">{{ $t('validator.required') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.selectedUser.$error}">
					<div class="icon-left"><icon-user /></div>
					<div class="icon-right" v-if="choose_people.selectedUser != ''" @click.stop="choose_people.selectedUser = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="choose_people.selectedUser"
						v-bind:class="{populate: choose_people.selectedUser != ''}"
						class="select-tags-tbf"
						:options="users"
						:allow-empty="false"
						:show-labels="false"
						track-by="id"
						label="name"
						@input="getPopleData()"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.peoples.choose_people-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconUser from '../../../../Icons/User'
	import IconClose from '../../../../Icons/Close'

	export default {
		components: {
			IconUser,
			IconClose
		},
		props: {
            choose_people: Object,
			users: Array,
			validator: Object
		},
		data() {
			return {
				loaded: false
			}
		},
		async mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		},

		methods: {
			getPopleData() {
				this.$root.$emit('userDataSelect')
			}
		}
	}
</script>